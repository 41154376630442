import { EnhancedStore } from "@reduxjs/toolkit";
import config from "../config";
import { TMarketSession } from "../events/EventDefinitions";
import { AuthRequestMessage, DisconnectReason, IAnnouncementMessage, IAuthorizedMessage, IDisconnectMessage, IMarketSessionMessage, IWsMessage } from "./MessageDefs";
import MessageType from "./MessageType";
import { logout } from "src/redux/auth";
import { eventDispatch } from "src/events/EventManager";

interface IAnnouncement {
  text: string;
  time: number;
}

/**
 * WSClient manages the websocket connection to Twix. This connection carries real-time updates
 * such as market alerts, position updates, messaging, etc.
 */
class WSClient {
  static RECONNECT_INTERVAL = 10000;
  static RECONNECT_MAX_ATTEMPTS = 6;

  private _token: string;
  private _sid: string;
  private _ver: string;
  private _ws: WebSocket;
  private _connected: boolean = false;
  private _requestedClose: boolean = false;
  private _reconnectEvent?: NodeJS.Timeout;
  private _reconnectAttempts: number = 0;
  announcements: IAnnouncement[] = [];
  private _session: TMarketSession = 'closed';

  public store: EnhancedStore;

  private _connectionOpened() {
    this._connected = true;
    if (this._reconnectEvent) {
      this._reconnectAttempts = 0;
      clearInterval(this._reconnectEvent);
    }
  }

  private _connectionClosed() {
    this._connected = false;
    this._ws = null;

    if (!this._requestedClose && this._reconnectAttempts < WSClient.RECONNECT_MAX_ATTEMPTS) {
      this._reconnectEvent = setTimeout(() => {
        this._reconnectAttempts++;
        eventDispatch('tw.debug.notice', 'Reconnecting to Tradeworks Server (Twix)');
        this.start(this._token);
      }, WSClient.RECONNECT_INTERVAL);
    } else {
      this._requestedClose = false;
      this.store.dispatch(logout());
    }
    eventDispatch('tw.debug.notice', 'Disconnected from Tradeworks Server (Twix)');
  }

  private _messageReceived(evt: MessageEvent<string>) {
    const msg = JSON.parse(evt.data);

    // Pre-Disconnect Message
    if (msg.$T === MessageType.Disconnect) {
      const tmsg = msg as IDisconnectMessage;

      // Log the reason for the disconnect
      let consoleMsg: string;
      switch (tmsg.Reason) {
        case DisconnectReason.ServerShutdown:
          consoleMsg = 'Disconnecting from Tradeworks Server: Server Shutdown';
          // In the case of a server shutdown (usually just a quick reboot), we should
          // keep trying to reconnect. Set _requestedClose = false tells the disconnect
          // handler to retry connection
          this._requestedClose = false;
          break;
        case DisconnectReason.AcctLocked:
          consoleMsg = 'Disconnecting from Tradeworks Server: Account Locked';
          this._requestedClose = true;
          break;
        case DisconnectReason.Admin:
          consoleMsg = 'Disconnecting from Tradeworks Server: Administrative';
          this._requestedClose = true;

          break;
        default:
          consoleMsg = 'Disconnecting from Tradeworks Server';
          this._requestedClose = true;
      }

      eventDispatch('tw.debug.notice', consoleMsg);
      return;
    }

    // Connection Authorized
    if (msg.$T === MessageType.Authorized) {
      const tmsg = msg as IAuthorizedMessage;
      this._sid = tmsg.sid;
      this._session = tmsg.session;
      this._ver = tmsg.ver;
      // This event usually fires before the debug console has mounted and rendered... which
      // prevents this message from being displayed
      eventDispatch('tw.debug.notice', `Connected to Tradeworks Server (Twix v${this._ver})`);
      return;
    }

    // Announcement!
    if (msg.$T === MessageType.Announcement) {
      const tmsg = msg as IAnnouncementMessage;

      // Stick the announcement at the beginning of the announcements array
      this.announcements.unshift({
        text: tmsg.Text,
        time: tmsg.Time
      });
      // Show the announcement modal
      eventDispatch('tw.modal.show', { modalName: 'announcement', useCurtain: true });
      return;
    }

    // Market Session Changed
    if (msg.$T === MessageType.MarketSession) {
      const tmsg = msg as IMarketSessionMessage;

      // If the new market session is the same as the current, we don't need to do anything
      if (this._session === tmsg.Session) return;

      // Log the session change
      let consoleMsg: string;
      if (this._session === 'closed' && tmsg.Session === 'pre') {
        consoleMsg = 'Premarket is now open';
      } else if (this._session === 'pre' && tmsg.Session === 'market') {
        consoleMsg = 'Market is now open';
      } else if (this._session === 'market' && tmsg.Session === 'post') {
        consoleMsg = 'Market is closed. Post-Market is now open';
      } else if (this._session === 'post' && tmsg.Session === 'closed') {
        consoleMsg = 'Post-Market is now closed';
      } else {
        consoleMsg = 'Market is now closed';
      }
      eventDispatch('tw.debug.notice', consoleMsg);

      // Update the session
      this._session = tmsg.Session;

      // Dispatch session change event
      eventDispatch('tw.market.session', { session: tmsg.Session });

      return;
    }
  }

  start(token: string) {
    if (this._ws) {
      return;
    }

    this._token = token;

    this._ws = new WebSocket(config.TW_DEBUG ? 'ws://twix.tradeworks.test:8080/wss' : 'wss://twix.tradeworks.com/wss');
    this._ws.onopen = () => {
      // Send JWT on socket open (we only have 5 seconds to identify ourselves)
      this.send(new AuthRequestMessage({
        Token: token
      }));
      this._connectionOpened();
    };
    this._ws.onclose = () => this._connectionClosed();
    this._ws.onmessage = (evt) => this._messageReceived(evt);
  }

  send<T extends IWsMessage>(data: T) {
    this._ws.send(JSON.stringify(data));
  }

  stop() {
    if (this._ws.readyState === this._ws.OPEN) {
      this._requestedClose = true;
      this._ws.close();
    }
  }

  isConnected(): boolean {
    return this._connected;
  }

  marketSession(): TMarketSession {
    return this._session;
  }
}

const wsClient = new WSClient();
export default wsClient;