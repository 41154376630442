import { TMarketSession } from "src/events/EventDefinitions";
import MessageType from "./MessageType";

export interface IWsMessage {
    $T: MessageType;
}

export interface IAuthorizedMessage extends IWsMessage {
    sid: string;
    session: TMarketSession;
    ver: string;
}

export interface IAuthRequestMessage {
    Token: string;
}

export class AuthRequestMessage implements IAuthRequestMessage, IWsMessage {
    $T: MessageType;
    Token: string;

    constructor(data: IAuthRequestMessage) {
        this.$T = MessageType.AuthRequest;
        this.Token = data.Token;
    }
}

export interface IAnnouncementMessage extends IWsMessage {
    Text: string;
    Time: number;
}

export interface IMarketSessionMessage extends IWsMessage {
    Session: TMarketSession;
}

export enum DisconnectReason {
    AuthTimeout = 1,
    AuthRejected = 2,
    ServerShutdown = 3,
    Admin = 4,
    AcctDeleted = 5,
    AcctLocked = 6
}

export interface IDisconnectMessage extends IWsMessage {
    Reason: DisconnectReason;
}