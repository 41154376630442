import HttpClient, { authHeader } from './HttpClient';
import {
  IAuthLoginResponse,
  IBrokerAccount,
  IOrder,
  IPosition,
  IQuoteboard,
  IQuoteboardResponse,
  IResponse,
  ISymbolGroup,
  ISymbolGroupResponse,
  IModifySymbolGroupRequest,
  INewSymbolGroupRequest,
  TSymbol,
  IQuoteboardSymbolRequest,
  ISectorTreeElement,
  ISymbolGetResponse,
  IAccountSubscription,
  ICreateBrokerAccountRequest,
  ISymbolGroupSymbolRequest
} from './ObjectDefs';

const Auth_Refresh = (): Promise<IAuthLoginResponse> =>
  HttpClient.post('/Auth_Refresh', {}, { headers: authHeader() });

const Quoteboards_Get = (): Promise<IQuoteboard[]> =>
  HttpClient.get('/Quoteboards_Get', { headers: authHeader() });

const Quoteboard_Create = (quoteboard: IQuoteboard): Promise<IQuoteboardResponse> =>
  HttpClient.post('/Quoteboard_Create', quoteboard, { headers: authHeader() });

const Quoteboard_Delete = (id: number): Promise<IResponse> =>
  HttpClient.post('/Quoteboard_Delete', { Id: id }, { headers: authHeader() });

const Quoteboard_AddSymbol = (symbol: IQuoteboardSymbolRequest): Promise<IQuoteboardResponse> =>
  HttpClient.post('/Quoteboard_AddSymbol', symbol, { headers: authHeader() });

const Quoteboard_MoveSymbol = (symbol: IQuoteboardSymbolRequest): Promise<IQuoteboardResponse> =>
  HttpClient.post('/Quoteboard_MoveSymbol', symbol, { headers: authHeader() });

const Quoteboard_RemoveSymbol = (symbol: Omit<IQuoteboardSymbolRequest, "Column" | "Row">): Promise<IQuoteboardResponse> =>
  HttpClient.post('/Quoteboard_RemoveSymbol', symbol, { headers: authHeader() });

const Symbol_Get = (symbol: string): Promise<ISymbolGetResponse> =>
  HttpClient.get(`/Symbol_Get?s=${symbol}`, { headers: authHeader() });

const Symbol_Lookup = (query: string, types?: string[], exchange?: string, group?: number): Promise<TSymbol[]> =>
  HttpClient.get(`/Symbol_Lookup?q=${query}&t=${types.join('|')}${exchange ? `&e=${exchange}` : ''}${group ? `&g=${group}` : ''}`, { headers: authHeader() });

const Trading_GetAccounts = (): Promise<IBrokerAccount[]> =>
  HttpClient.get('/Trading_GetAccounts', { headers: authHeader() });

const Trading_AddAccount = (broker_acct: ICreateBrokerAccountRequest): Promise<IBrokerAccount> =>
  HttpClient.post('/Trading_AddAccount', broker_acct, { headers: authHeader() });

const Trading_GetOrders = (): Promise<IOrder[]> =>
  HttpClient.get('/Trading_GetOrders', { headers: authHeader() });

const Trading_PlaceOrder = (order: IOrder): Promise<IOrder[]> =>
  HttpClient.post('/Trading_PlaceOrder', order, { headers: authHeader() });

const Trading_GetPositions = (): Promise<IPosition[]> =>
  HttpClient.get('/Trading_GetPositions', { headers: authHeader() });

const SymbolGroups_GetGroups = (): Promise<ISymbolGroup[]> =>
  HttpClient.get('/SymbolGroups_Get', { headers: authHeader() });

const SymbolGroup_Get = (groupId: number, getAll: boolean = false, limit: number = 50, page: number = 0): Promise<TSymbol[]> =>
  HttpClient.get(`/SymbolGroup_Get?group_id=${groupId}${getAll ? '&all' : ''}${limit !== null ? `&limit=${limit}` : ''}${page !== null ? `&page=${page}` : ''}`, { headers: authHeader() });

const SymbolGroup_Create = (newGroup: INewSymbolGroupRequest): Promise<ISymbolGroupResponse> =>
  HttpClient.post('/SymbolGroup_Create', newGroup, { headers: authHeader() });

const SymbolGroup_Modify = (list: IModifySymbolGroupRequest): Promise<ISymbolGroupResponse> =>
  HttpClient.post('/SymbolGroup_Modify', list, { headers: authHeader() });

const SymbolGroup_Delete = (listId: number): Promise<ISymbolGroupResponse> =>
  HttpClient.post('/SymbolGroup_Delete', { Id: listId }, { headers: authHeader() });

const SymbolGroup_AddSymbol = (symbol: ISymbolGroupSymbolRequest): Promise<ISymbolGroupResponse> =>
  HttpClient.post('/SymbolGroup_AddSymbol', symbol, { headers: authHeader() });

const SymbolGroup_RemoveSymbol = (symbol: ISymbolGroupSymbolRequest): Promise<ISymbolGroupResponse> =>
  HttpClient.post('/SymbolGroup_RemoveSymbol', symbol, { headers: authHeader(), });

const SectorTree_Get = (): Promise<ISectorTreeElement[]> =>
  HttpClient.get('/SectorTree_Get', { headers: authHeader() });

const Account_GetSubscription = (): Promise<IAccountSubscription> =>
  HttpClient.get('/Account_GetSubscription', { headers: authHeader() });

const MainApi = {
  Auth_Refresh,
  Quoteboards_Get,
  Quoteboard_Create,
  Quoteboard_Delete,
  Quoteboard_AddSymbol,
  Quoteboard_MoveSymbol,
  Quoteboard_RemoveSymbol,
  Symbol_Get,
  Symbol_Lookup,
  Trading_GetAccounts,
  Trading_AddAccount,
  Trading_GetOrders,
  Trading_PlaceOrder,
  Trading_GetPositions,
  SymbolGroups_GetGroups,
  SymbolGroup_Get,
  SymbolGroup_Create,
  SymbolGroup_Modify,
  SymbolGroup_Delete,
  SymbolGroup_AddSymbol,
  SymbolGroup_RemoveSymbol,
  SectorTree_Get,
  Account_GetSubscription,
};

export default MainApi;
