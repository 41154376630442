import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from 'src/config';
import { useSelector } from 'react-redux';
import { isAuthenticated } from 'src/redux/auth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const authenticated = useSelector(isAuthenticated);

  if (config.MAINTENANCE_MODE) {
    return <Navigate to="/maintenance" />;
  }

  if (authenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
