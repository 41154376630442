import { FC } from 'react';
import './SplashScreen.scss';

interface ISplashScreenProps {

}

const SplashScreen: FC<ISplashScreenProps> = (props: ISplashScreenProps) => {
    return (
        <div className="tw-loading">
            <img
                className="tw-gear"
                src="/tw_logo_anim-dark.gif"
                width="150"
                height="150"
                alt="TradeWorks Loading"
            />
        </div>
    );
};

export default SplashScreen;
