import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from 'src/config';

interface MaintenanceGuardProps {
  children: ReactNode;
}

const MaintenanceGuard: FC<MaintenanceGuardProps> = ({ children }) => {
  if (!config.MAINTENANCE_MODE) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

MaintenanceGuard.propTypes = {
  children: PropTypes.node
};

export default MaintenanceGuard;
