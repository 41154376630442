import wsClient from "src/wsclient/WSClient";
import { eventDispatch } from "src/events/EventManager";

type TickerListener<TMessageType extends MarketData> = (data: TMessageType) => void;
type MarketData = qmci.IPriceData | qmci.IQuoteData | qmci.IOrderData | qmci.ITradeData;

interface ITickerSubscriber {
  subscription: qmci.ISubscription;
  listener: TickerListener<MarketData>;
}

/**
 * QMTicker manages the streaming data connection to QuoteMedia. It allows application components
 * to "subscribe" to symbols of interest by passing the symbol name, data type, and listener (callback)
 */
class QMTicker {
  private _sid: string;
  private _qmci: qmci.Stream;
  private _connected: boolean = false;
  private _market_open: boolean = false;
  // private _market_session: TMarketSession = 'closed';
  private _subscribers: ITickerSubscriber[] = [];
  private _subscriptions: qmci.ISubscription[] = [];
  private _keepAlive: NodeJS.Timer;
  private _requestClose: boolean = false;
  private _lastTimestamp: number = 0;
  private _lastPriceData: qmci.IPriceData[] = [];
  private _lastQuoteData: qmci.IQuoteData[] = [];

  private _connectionOpened() {
    this._connected = true;

    // various components may have subscribed to market data before the QM connection was actually open,
    // so we need to check the array of subscribers against the array of actual subscriptions we've sent to QM.
    // tl;dr: check for unsubscribed subscribers
    let newSubscriptions = [];
    this._subscribers.forEach(s => {
      if (newSubscriptions.some(x => x === s.subscription) === false && this._subscriptions.some(x => x === s.subscription) === false) {
        newSubscriptions.push({ ...s.subscription });
        this._qmci.subscribe(
          s.subscription.symbol,
          s.subscription.type,
          { skipHeavyInitialLoad: true },
          (err, result) => {
            if (err) {
              console.log('Failed to subscribe: %s', err);
              return;
            }
            result.subscribed.forEach(subscription => {
              if (!this._subscriptions.some(s => s.symbol === subscription.symbol && s.type === subscription.type)) {
                eventDispatch('tw.debug.notice', `Subscribed to ${subscription.symbol}`);
                this._subscriptions.push({ ...subscription });
                eventDispatch('tw.streaming.subscribed', subscription);
              }
            });
          }
        );
      }
    });

    // announce the market connection status (we assume the market is closed until we know otherwise)
    eventDispatch('tw.market.connection', { status: 'market_closed' });
    eventDispatch('tw.debug.notice', 'Connected to streaming market data');
  }

  private _connectionClosed() {
    const sid = this._sid;

    this._sid = null;
    this._connected = false;
    this._qmci = null;

    clearInterval(this._keepAlive);

    // announce the market connection status
    eventDispatch('tw.market.connection', { status: 'disconnected' });
    eventDispatch('tw.debug.notice', 'Disconnected from streaming market data');

    // attempt reconnection?
    if (!this._requestClose) {
      this.start(sid);
    }
  }

  private _messageReceived(msg: MarketData) {
    // get the message data type
    const dataType = qmci.Streamer.marketDataTypes.get(msg);

    // update the market status if neccessary
    if (dataType === qmci.Streamer.marketDataTypes.PRICEDATA) {
      const price = msg as qmci.IPriceData;

      if (price.open && price.close === null && !this._market_open) {
        this._market_open = true;
        eventDispatch('tw.market.connection', { status: 'market_open' });
        eventDispatch('tw.debug.notice', 'Market is open');
      }

      if (price.close && this._market_open) {
        this._market_open = false;
        eventDispatch('tw.market.connection', { status: 'market_closed' });
        eventDispatch('tw.debug.notice', 'Market is closed');
      }

      const idx = this._lastPriceData.findIndex(pd => pd.symbol === price.symbol);
      if (idx === -1) {
        this._lastPriceData.push({ ...price });
      } else {
        this._lastPriceData[idx] = { ...price };
      }
    } else if (dataType === qmci.Streamer.marketDataTypes.QUOTE) {
      const quote = msg as qmci.IQuoteData;

      const idx = this._lastQuoteData.findIndex(pd => pd.symbol === quote.symbol);
      if (idx === -1) {
        this._lastQuoteData.push({ ...quote });
      } else {
        this._lastQuoteData[idx] = { ...quote };
      }
    }

    // iterate thru subscribers to find listeners
    this._subscribers.forEach(s => {
      // does this subscriber match the received message
      if (s.subscription.symbol === msg.symbol && s.subscription.type === dataType) {
        // pass the message to the listener
        s.listener(msg);
      }
    });
  }

  start(sid: string) {
    if (this._sid) {
      return;
    }

    this._sid = sid;

    // Open new streamer connection
    qmci.Streamer.open(
      {
        host: 'https://app.quotemedia.com/cache',
        cors: true,
        rejectExcessiveConnection: false,
        conflation: null,
        format: 'application/json',
        credentials: { sid },
      },
      (err: qmci.IError, stream: qmci.Stream) => {
        if (err) {
          eventDispatch('tw.debug.error', `Could not stream market data: ${err.description}`);
          console.log('QMTicker Init Failed: %s', err);
          return;
        }

        // Store stream handle
        this._qmci = stream;

        // QMCI supports listeners for open, close, message, sequence, error, initialDataSent, resubscribeMessage, stats and slow
        // this._qmci.on('open', () => this._connectionOpened());
        this._qmci.on('close', () => this._connectionClosed());
        this._qmci.on('message', (msg: MarketData) => this._messageReceived(msg));
        this._qmci.on('heartbeat', (msg: qmci.IHeartbeat) => this._lastTimestamp = msg.timestamp);
        this._qmci.on('slow', () => {
          // not sure how to use this yet...
        });

        this._connectionOpened();

        this._keepAlive = setInterval(() => {
          this._qmci.getSessionStats();
        }, 60000);
      }
    );
  }

  stop() {
    this._requestClose = true;
    this._subscriptions = [];
    if (this._qmci) {
      this._qmci.close();
    } else {
      this._sid = null;
      this._connected = false;
      this._qmci = null;

      if (this._keepAlive) {
        clearInterval(this._keepAlive);
      }
    }
  }

  isConnected(): boolean {
    return this._qmci && this._connected;
  }

  isMarketOpen(): boolean {
    return this._qmci && this._connected && this._market_open;
  }

  sid(): string {
    return this._sid;
  }

  serverTime(): number {
    return this._lastTimestamp;
  }

  subscriptions(): qmci.ISubscription[] {
    return this._subscriptions;
  }

  subscribe<TMarketData extends MarketData>(symbols: string | string[], dataType: string, listener: TickerListener<TMarketData>) {
    if (typeof symbols === 'string') {
      symbols = [symbols];
    }

    // Check if QMCI is already subscribed to this symbol/dataType pair
    let newSubscriptions: string[] = [];
    symbols.forEach((symbol) => {
      if (!this._subscriptions.some(s => s.symbol === symbol && s.type === dataType)) {
        newSubscriptions.push(symbol);
      }
    });

    if (newSubscriptions.length) {
      if (this._connected) {
        // We're connected... so let's subscribe to the symbol/dataType pair
        this._qmci.subscribe(newSubscriptions, [dataType], { skipHeavyInitialLoad: true }, (err, result) => {
          if (err) {
            // Subscription failed. I guess this would be an entitlement problem?
            console.log('Failed to subscribe: %s', err);
            return;
          }
          // Store the new subscriptions...
          result.subscribed.forEach(subscription => {
            if (!this._subscriptions.some(s => s.symbol === subscription.symbol && s.type === subscription.type)) {
              eventDispatch('tw.debug.notice', `Subscribed to ${subscription.symbol}`);
              this._subscriptions.push({ ...subscription });
              eventDispatch('tw.streaming.subscribed', subscription);
            }
          });
        });
      }
    }

    symbols.forEach((symbol) => {
      this._subscribers.push({
        subscription: {
          symbol,
          type: dataType,
          entitlement: ''
        },
        listener
      });
    });
  }

  unsubscribe<TMarketData extends MarketData>(symbols: string | string[], dataType: string, listener: TickerListener<TMarketData>) {
    if (typeof symbols === 'string') {
      symbols = [symbols];
    }

    symbols.forEach((symbol) => {
      // Find the index of the subscription we're removing
      const idx = this._subscribers.findIndex(s => s.subscription.symbol === symbol && s.subscription.type === dataType && s.listener === listener);
      if (idx !== -1) {
        // Splice it from the array
        this._subscribers.splice(idx, 1);
      }
    });

    /**
     * NOTE: After DAYS of tinkering with the following code... I'm throwing in the towel for now. Here's why...
     * This method was SUPPOSED to have two jobs: 
     * 
     *     1. to remove the event listener (the subscriber) from the _subscribers array
     *     2. to send "unsubscribe" messages to QM if the removed subscriber was the last one for it's given symbol
     * 
     * Unfortunately... due to the way useEffect works, most components have to call this method EVERY SINGLE TIME 
     * price data is updated. That's not a problem for job #1, but job #2 causes an endless cascade of subscribe/unsubscribe
     * messages and confirmations, which in turn trigger the downstream useEffect callbacks even more. So, we're just disabling 
     * job #2 for now. A potential consequence of this is that QMTicker will accumulate QM symbol subscriptions over time... 
     * even if components don't neccessarily need them anymore. Perhaps a fix for this is to run a "cleanup" method every so 
     * often to unsubscribe from unused symbols.
     */

    // let newUnsubscriptions: string[] = [];
    // symbols.forEach((symbol) => {
    //   // Check for any remaining subscribers to this data
    //   if (!this._subscribers.some(s => s.subscription.symbol === symbol && s.subscription.type === dataType)) {
    //     // There are no more subscribers for this data, so we can tell QM to stop sending it
    //     newUnsubscriptions.push(symbol);
    //   }
    // });

    // console.log('ready to send Unsubscriptions', newUnsubscriptions);

    // if (this._connected && newUnsubscriptions.length) {
    //   this._qmci.unsubscribe(
    //     newUnsubscriptions,
    //     dataType,
    //     (err, result) => {
    //       if (err) {
    //         console.log('Failed to unsubscribe: %s', err);
    //         return;
    //       }

    //       // Remove subscriptions from array
    //       result.unsubscribed.forEach(u => {
    //         const idx = this._subscriptions.findIndex(s => s === u);
    //         if (idx !== -1) {
    //           this._subscriptions.splice(idx, 1);
    //         }
    //       });
    //     }
    //   );
    // }
  }

  getLastPriceData(symbol: string): qmci.IPriceData {
    return this._lastPriceData.find(pd => pd.symbol === symbol);
  }

  getLastQuoteData(symbol: string): qmci.IQuoteData {
    return this._lastQuoteData.find(pd => pd.symbol === symbol);
  }

  getLastPriceField(): 'preMarketLast' | 'last' | 'postMarketLast' | 'previousClose' {
    switch (wsClient.marketSession()) {
      default:
      case 'closed': return 'previousClose';
      case 'pre': return 'preMarketLast';
      case 'market': return 'last';
      case 'post': return 'postMarketLast';
    }
  }
}

const qmTicker = new QMTicker();
export default qmTicker;