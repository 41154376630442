import './App.scss';
import { Suspense, lazy, useCallback, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GuestGuard from "./components/Guard/GuestGuard";
import AuthGuard from "./components/Guard/AuthGuard";
import SplashScreen from "./pages/SplashScreen";
import MaintenanceGuard from "./components/Guard/MaintenanceGuard";
import { useDispatch, useSelector } from 'react-redux';
import { auth, isLoaded, login, logout } from './redux/auth';
import MainApi from './api/MainApi';
import { TWLgIcons, TWSmIcons } from './icons';
import { eventDispatch } from './events/EventManager';

const Loadable =
  (Component: React.ComponentType<any>) => (props: { [key: string]: any }) =>
  (
    <Suspense fallback={<SplashScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Non-Auth Pages
const LoginPage = Loadable(lazy(() => import("./pages/non-auth/Login")));
const MaintenancePage = Loadable(lazy(() => import("./pages/non-auth/Maintenance")));

// Auth Pages
const MainPage = Loadable(lazy(() => import("./pages/auth/MainPage")));

function App() {
  const loaded = useSelector(isLoaded);
  const dispatch = useDispatch();

  const refreshEvent = useRef<NodeJS.Timeout>();
  const refresh = useCallback(() => {
    return MainApi.Auth_Refresh()
      .then((resp) => {
        if (resp.auth) {
          dispatch(login(resp));
        } else {
          dispatch(logout());
        }
      })
      .catch((e) => {
        eventDispatch('tw.debug.warning', 'Failed to refresh auth token');
        dispatch(auth(null));
        refreshEvent.current = null;
      });
  }, [dispatch]);

  // Preload icons
  useEffect(() => {
    TWSmIcons.map(async icon => {
      const img = new Image();
      img.src = `/images/icons/16/${icon}.png`;
      return img;
    });
    TWLgIcons.map(async icon => {
      const img = new Image();
      img.src = `/images/icons/32/${icon}.png`;
      return img;
    });
  }, []);

  const isInitialized = useRef<boolean>(false);
  useEffect(() => {
    if (!isInitialized.current) {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken) {
        refresh();
      } else {
        dispatch(auth(null));
      }

      isInitialized.current = true;
    }
  }, [dispatch, refresh]);

  if (!loaded) {
    return <SplashScreen />;
  }

  return <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <AuthGuard>
            <MainPage />
          </AuthGuard>
        }
      />
      <Route path="/auth">
        <Route
          path="login"
          element={
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          }
        />
      </Route>
      <Route
        path="/maintenance"
        element={
          <MaintenanceGuard>
            <MaintenancePage />
          </MaintenanceGuard>
        }
      />
    </Routes>
  </BrowserRouter>;
}

export default App;
