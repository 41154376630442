import axios, { AxiosResponse } from "axios";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> { }
}

export function authHeader() {
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return {};
}

export const API_HOSTNAME =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://twix.tradeworks.test:8080/api/"
    : "https://twix.tradeworks.com/api/";

// const controller = new AbortController();

const HttpClient = axios.create({
  baseURL: API_HOSTNAME,
  headers: {
    "Content-Type": "application/json",
  },
  // signal: controller.signal
});

HttpClient.interceptors.response.use(
  ({ data }: AxiosResponse) => data,
  (error: any) => Promise.reject(error)
);

export default HttpClient;
