export const TWSmIcons = [
  'account',
  'activity',
  'basket',
  'buy',
  'cancel',
  'copy-file',
  'criteria',
  'csv-file',
  'dashboard',
  'export',
  'filter',
  'function',
  'help',
  'library',
  'new',
  'new-file',
  'open',
  'orders',
  'pencil',
  'positions',
  'rename',
  'rules',
  'save',
  'save-as',
  'sectors',
  'sell',
  'settings',
  'strategy',
  'symbol-group',
  'symbol-group-private',
  'symbol-lookup',
  'trash',
  'verify',
  'zoom-in',
  'zoom-out'
] as const;

export const TWLgIcons = [
  'buy',
  'connected',
  'disconnected',
  'price-ladder',
  'quote',
  'sell',
  'settings',
  'symbol-grid',
  'symbol-group',
  'tools',
  'trading'
] as const;

export type TSmIconName = (typeof TWSmIcons)[number];
export type TLgIconName = (typeof TWLgIcons)[number];