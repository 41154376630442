import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import MainApi from "src/api/MainApi";
import { IAuthLoginResponse, IAuthUser, IJwtPayload } from "src/api/ObjectDefs";
import qmTicker from "src/quotemedia/QMTicker";
import wsClient from "src/wsclient/WSClient";

export interface IAuthState {
  user: IAuthUser;
  isLoaded: boolean;
  isAuthenticated: boolean;
}

// JWT refresh event
let refreshEvent: NodeJS.Timeout;

/**
 * Logout User
 */
const logoutReducer: CaseReducer<IAuthState> = (state) => {
  // Clear the access token from local storage
  window.localStorage.removeItem('accessToken');

  // Update the auth state
  state.isLoaded = true;
  state.isAuthenticated = false;
  state.user = null;

  // Stop QMTicker if connected
  if (qmTicker.isConnected()) {
    qmTicker.stop();
  }

  // Stop WSClient if connected
  if (wsClient.isConnected()) {
    wsClient.stop();
  }

  // Clear refresh event if it's running
  if (refreshEvent) {
    clearInterval(refreshEvent);
  }
};

const authReducer: CaseReducer<IAuthState, PayloadAction<IAuthUser>> = (state, action) => {
  state.isLoaded = true;
  if (action.payload) {
    state.isAuthenticated = true;
    state.user = { ...action.payload };
  } else {
    state.isAuthenticated = false;
    state.user = null;
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoaded: false,
    isAuthenticated: false
  } satisfies IAuthState as IAuthState,
  reducers: {
    logout: logoutReducer,
    auth: authReducer,
  }
});

export const { auth, logout } = authSlice.actions;

/**
 * Process Login Response
 */
export const login: any = (response: IAuthLoginResponse) => (dispatch) => {
  // Store the access token
  window.localStorage.setItem('accessToken', response.access_token);

  // update the auth state
  dispatch(auth(response.user));

  // Decode the JWT payload
  const payload = jwtDecode(response.access_token) as IJwtPayload;

  // Initialize QMTicker
  qmTicker.start(payload.sid);

  // Start Twix WebSocket Client
  wsClient.start(response.access_token);

  // Set JWT refresh event
  refreshEvent = setInterval(() => {
    MainApi.Auth_Refresh()
      .then((resp) => {
        // Was refresh successful?
        if (!resp.auth) {
          // If not... update the auth state
          dispatch(logout());
          return;
        }

        // Refresh was successful... update the access token
        window.localStorage.setItem('accessToken', resp.access_token);
      });

  },
    // JWT expires after 60 minutes... so we refresh after 59 
    59 * 60 * 1000);
};

// Selectors
const isAuthenticated = (state) => state.auth.isAuthenticated;
const isLoaded = (state) => state.auth.isLoaded;
const authUser = (state) => state.auth.user;

export { isAuthenticated, isLoaded, authUser };

export default authSlice.reducer;