import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import quoteboardReducer from "./quoteboard";
import symbolGroupReducer from "./symbolGroup";

const store = configureStore({
  reducer: {
    auth: authReducer,
    quoteboard: quoteboardReducer,
    symbolGroup: symbolGroupReducer,
  }
});

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;