import { EventTypes } from "./EventDefinitions";

// Strong typing for event handlers
type EventNames = keyof EventTypes;
type GetPayloadType<T extends EventNames> = EventTypes[T];
type EventHandler<T extends EventNames> = (payload: GetPayloadType<T>, stopPropogation: () => void) => void;

// Event listeners
const registeredEvents: Record<string, EventHandler<EventNames>[]> = {};

/**
 * Register event listener
 */
export const eventListen: <T extends EventNames>(eventName: T, handler: (payload: GetPayloadType<T>) => void) => void = (eventName, handler) => {
  if (typeof registeredEvents[eventName] === 'undefined') {
    registeredEvents[eventName] = [];
  }
  registeredEvents[eventName].push(handler);
};

/**
 * Un-register event listener
 */
export const eventUnlisten: <T extends EventNames>(eventName: T, handler: (payload: GetPayloadType<T>) => void) => void = (eventName, handler) => {
  if (typeof registeredEvents[eventName] === 'undefined') {
    // no handlers for event
    return;
  }

  const idx = registeredEvents[eventName].findIndex(h => h === handler);
  if (idx !== -1) {
    registeredEvents[eventName].splice(idx, 1);
  }
};

/**
 * Dispatch event
 */
export const eventDispatch: <T extends EventNames>(eventName: T, payload: GetPayloadType<T>) => void = (eventName, payload) => {
  if (typeof registeredEvents[eventName] === 'undefined') {
    // no handlers for event
    return;
  }

  let propogate = true;
  const stopPropogation = () => {
    propogate = false;
  };

  for (let i in registeredEvents[eventName]) {
    const handler = registeredEvents[eventName][i];
    handler(payload, stopPropogation);
    if (!propogate) {
      break;
    }
  }
};