import { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isAuthenticated } from 'src/redux/auth';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const authenticated = useSelector(isAuthenticated);

  const location = useLocation();

  if (!authenticated) {
    const next = location.pathname !== '/' ? `?next=${location.pathname}` : '';
    return <Navigate to={`/auth/login${next}`} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
