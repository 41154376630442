import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import MainApi from "src/api/MainApi";
import { IModifySymbolGroupRequest, INewSymbolGroupRequest, ISymbolGroup, ISymbolGroupResponse, TSymbol } from "src/api/ObjectDefs";
import { eventDispatch } from "src/events/EventManager";
import { RootState } from "./store";

interface ISymbolGroupState {
  initialized: boolean;
  symbolGroups: ISymbolGroup[];
  symbolGroupPages: Record<number, number>;
}

export const initSymbolGroups = createAsyncThunk<ISymbolGroup[], void, { rejectValue: string, error: string }>(
  'symbolGroup/init',
  async (_, thunkApi) => {
    return await MainApi.SymbolGroups_GetGroups()
      .then(resp => {
        return thunkApi.fulfillWithValue(resp);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not load symbol groups');
      });
  }
);

export const populateSymbols = createAsyncThunk<
  { symbolGroupId: number, symbols: TSymbol[] },
  number,
  { rejectValue: string, error: string, state: RootState }
>(
  'symbolGroup/populate',
  async (symbolGroupId, thunkApi) => {
    let page = thunkApi.getState().symbolGroup.symbolGroupPages[symbolGroupId] ?? 0;
    if (page === -1) {
      return thunkApi.fulfillWithValue({ symbolGroupId, symbols: [] });
    }
    return await MainApi.SymbolGroup_Get(symbolGroupId, false, 50, page)
      .then(resp => {
        return thunkApi.fulfillWithValue({ symbolGroupId, symbols: resp });
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not load symbols');
      });
  }
);

export const addSymbolGroup = createAsyncThunk<ISymbolGroup, INewSymbolGroupRequest, { rejectValue: string, error: string }>(
  'symbolGroup/add',
  async (symbolGroup, thunkApi) => {
    return await MainApi.SymbolGroup_Create(symbolGroup)
      .then(resp => {
        if (resp.code !== 200) {
          return thunkApi.rejectWithValue(resp.msg);
        }

        return thunkApi.fulfillWithValue(resp.symbolGroup);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not add symbol list');
      });
  }
);

export const editSymbolGroup = createAsyncThunk<ISymbolGroup, IModifySymbolGroupRequest, { rejectValue: string, error: string }>(
  'symbolGroup/edit',
  async (symbolGroup, thunkApi) => {
    return await MainApi.SymbolGroup_Modify(symbolGroup)
      .then(resp => {
        if (resp.code !== 200) {
          return thunkApi.rejectWithValue(resp.msg);
        }

        return thunkApi.fulfillWithValue(resp.symbolGroup);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not modify symbol list');
      });
  }
);

export const removeSymbolGroup = createAsyncThunk<number, number, { rejectValue: string, error: string }>(
  'symbolGroup/remove',
  async (symbolGroupId, thunkApi) => {
    return await MainApi.SymbolGroup_Delete(symbolGroupId)
      .then(resp => {
        if (resp.code !== 200) {
          return thunkApi.rejectWithValue(resp.msg);
        }

        return thunkApi.fulfillWithValue(symbolGroupId);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not remove symbol list');
      });
  }
);

export const addSymbolGroupSymbol = createAsyncThunk<ISymbolGroup, { symbolGroupId: number, symbols: number[] }, { rejectValue: string, error: string }>(
  'symbolGroup/addSymbol',
  async (request, thunkApi) => {
    let updatedSymbolGroup: ISymbolGroupResponse;
    for (let idx in request.symbols) {
      updatedSymbolGroup = await MainApi.SymbolGroup_AddSymbol({
        SymbolGroupId: request.symbolGroupId,
        SymbolId: request.symbols[idx]
      });
      if (updatedSymbolGroup && updatedSymbolGroup.code !== 200) {
        return thunkApi.rejectWithValue('Could not add symbols');
      }
    }
    if (updatedSymbolGroup) {
      return thunkApi.fulfillWithValue(updatedSymbolGroup.symbolGroup);
    }
    return thunkApi.rejectWithValue('No symbols added');
  }
);

export const removeSymbolGroupSymbol = createAsyncThunk<ISymbolGroup, { symbolGroupId: number, symbols: number[] }, { rejectValue: string, error: string }>(
  'symbolGroup/removeSymbol',
  async (request, thunkApi) => {
    let updatedSymbolGroup: ISymbolGroupResponse;
    for (let idx in request.symbols) {
      updatedSymbolGroup = await MainApi.SymbolGroup_RemoveSymbol({
        SymbolGroupId: request.symbolGroupId,
        SymbolId: request.symbols[idx]
      });
      if (updatedSymbolGroup && updatedSymbolGroup.code !== 200) {
        return thunkApi.rejectWithValue('Could not remove symbols');
      }
    }
    if (updatedSymbolGroup) {
      return thunkApi.fulfillWithValue(updatedSymbolGroup.symbolGroup);
    }
    return thunkApi.rejectWithValue('No symbols removed');
  }
);

export const symbolGroupSlice = createSlice({
  name: 'symbolGroup',
  initialState: {
    initialized: false,
    symbolGroups: [],
    symbolGroupPages: {}
  } satisfies ISymbolGroupState as ISymbolGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initSymbolGroups.fulfilled, (state, action) => {
      state.initialized = true;
      state.symbolGroups = action.payload;
    });

    builder.addCase(populateSymbols.fulfilled, (state, action) => {
      if (0 === action.payload.symbols.length) return;

      const idx = state.symbolGroups.findIndex(sl => sl.Id === action.payload.symbolGroupId);
      if (idx !== -1) {
        if (state.symbolGroupPages[action.payload.symbolGroupId]) {
          state.symbolGroups[idx].Symbols = state.symbolGroups[idx].Symbols.concat([...action.payload.symbols]);
          state.symbolGroupPages[action.payload.symbolGroupId] = (action.payload.symbols.length === 50) ? state.symbolGroupPages[action.payload.symbolGroupId] + 1 : -1;
        } else {
          state.symbolGroups[idx].Symbols = [...action.payload.symbols];
          state.symbolGroupPages[action.payload.symbolGroupId] = (action.payload.symbols.length === 50) ? 1 : -1;
        }
      }
    });

    builder.addCase(addSymbolGroup.fulfilled, (state, action) => {
      state.symbolGroups.push(action.payload);
    });

    builder.addCase(editSymbolGroup.fulfilled, (state, action) => {
      const idx = state.symbolGroups.findIndex(sl => sl.Id === action.payload.Id);
      if (idx !== -1) {
        state.symbolGroups[idx] = { ...action.payload };
      }
    });

    builder.addCase(removeSymbolGroup.fulfilled, (state, action) => {
      const idx = state.symbolGroups.findIndex(sl => sl.Id === action.payload);
      if (idx !== -1) {
        state.symbolGroups.splice(idx, 1);
      }
    });

    builder.addMatcher(
      isAnyOf(
        addSymbolGroupSymbol.fulfilled,
        removeSymbolGroupSymbol.fulfilled,
      ),
      (state, action) => {
        const idx = state.symbolGroups.findIndex(sl => sl.Id === action.payload.Id);
        if (idx !== -1) {
          state.symbolGroups[idx] = { ...action.payload };
        }
      }
    );

    builder.addMatcher(
      isAnyOf(
        initSymbolGroups.rejected,
        addSymbolGroup.rejected,
      ),
      (_, action) => {
        eventDispatch('tw.ui.toast', {
          type: 'error',
          title: 'Symbol Group Error',
          message: action.payload
        });
      }
    );
  }
});

export default symbolGroupSlice.reducer;