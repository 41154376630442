import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IQuoteboard, IQuoteboardResponse } from "src/api/ObjectDefs";
import MainApi from "src/api/MainApi";
import { eventDispatch } from "src/events/EventManager";

interface IQuoteboardState {
  initialized: boolean;
  quoteboards: Record<number, IQuoteboard>;
}

export const initQuoteboards = createAsyncThunk<IQuoteboard[], void, { rejectValue: string, error: string }>(
  'quoteboard/init',
  async (_, thunkApi) => {
    return await MainApi.Quoteboards_Get()
      .then(resp => {
        return thunkApi.fulfillWithValue(resp);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not load quoteboards');
      });
  }
);

export const addQuoteboard = createAsyncThunk<IQuoteboard, string, { rejectValue: string, error: string }>(
  'quoteboard/add',
  async (qbName, thunkApi) => {
    return await MainApi.Quoteboard_Create({ Name: qbName, Symbols: [] })
      .then(resp => {
        if (resp.code !== 200) {
          return thunkApi.rejectWithValue(resp.msg);
        }

        return thunkApi.fulfillWithValue(resp.quoteboard);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not add quoteboard');
      });
  }
);

export const removeQuoteboard = createAsyncThunk<number, number, { rejectValue: string, error: string }>(
  'quoteboard/remove',
  async (qbId, thunkApi) => {
    return await MainApi.Quoteboard_Delete(qbId)
      .then(resp => {
        if (resp.code !== 200) {
          return thunkApi.rejectWithValue(resp.msg);
        }

        return thunkApi.fulfillWithValue(qbId);
      })
      .catch(() => {
        return thunkApi.rejectWithValue('Could not remove quoteboard');
      });
  }
);

export const addQuoteboardSymbols = createAsyncThunk<IQuoteboard, { quoteboardId: number, symbols: number[], column: number, row: number }, { rejectValue: string, error: string }>(
  'quoteboard/addSymbols',
  async (request, thunkApi) => {
    let updatedQuoteboard: IQuoteboardResponse;
    for (let idx in request.symbols) {
      updatedQuoteboard = await MainApi.Quoteboard_AddSymbol({
        QuoteboardId: request.quoteboardId,
        SymbolId: request.symbols[idx],
        Column: request.column,
        Row: request.row
      });
      if (updatedQuoteboard && updatedQuoteboard.code !== 200) {
        return thunkApi.rejectWithValue('Could not add symbols');
      }
    }
    if (updatedQuoteboard) {
      return thunkApi.fulfillWithValue(updatedQuoteboard.quoteboard);
    }
    return thunkApi.rejectWithValue('No symbols added');
  }
);

export const moveQuoteboardSymbols = createAsyncThunk<IQuoteboard, { quoteboardId: number, symbols: number[], column: number, row: number }, { rejectValue: string, error: string }>(
  'quoteboard/moveSymbols',
  async (request, thunkApi) => {
    let updatedQuoteboard: IQuoteboardResponse;
    for (let idx in request.symbols) {
      updatedQuoteboard = await MainApi.Quoteboard_MoveSymbol({
        QuoteboardId: request.quoteboardId,
        SymbolId: request.symbols[idx],
        Column: request.column,
        Row: request.row
      });
      if (updatedQuoteboard && updatedQuoteboard.code !== 200) {
        return thunkApi.rejectWithValue('Could not move symbols');
      }
    }
    if (updatedQuoteboard) {
      return thunkApi.fulfillWithValue(updatedQuoteboard.quoteboard);
    }
    return thunkApi.rejectWithValue('No symbols moved');
  }
);

export const removeQuoteboardSymbols = createAsyncThunk<IQuoteboard, { quoteboardId: number, symbols: number[] }, { rejectValue: string, error: string }>(
  'quoteboard/removeSymbols',
  async (request, thunkApi) => {
    let updatedQuoteboard: IQuoteboardResponse;
    for (let idx in request.symbols) {
      updatedQuoteboard = await MainApi.Quoteboard_RemoveSymbol({
        QuoteboardId: request.quoteboardId,
        SymbolId: request.symbols[idx]
      });
      if (updatedQuoteboard && updatedQuoteboard.code !== 200) {
        return thunkApi.rejectWithValue('Could not remove symbols');
      }
    }
    if (updatedQuoteboard) {
      return thunkApi.fulfillWithValue(updatedQuoteboard.quoteboard);
    }
    return thunkApi.rejectWithValue('No symbols removed');
  }
);

export const quoteboardSlice = createSlice({
  name: 'quoteboard',
  initialState: {
    initialized: false,
    quoteboards: {}
  } satisfies IQuoteboardState as IQuoteboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initQuoteboards.fulfilled, (state, action) => {
      state.initialized = true;
      action.payload.forEach(qb => {
        state.quoteboards[qb.Id] = qb;
      });
    });

    builder.addCase(addQuoteboard.fulfilled, (state, action) => {
      state.quoteboards[action.payload.Id] = action.payload;
    });

    builder.addCase(removeQuoteboard.fulfilled, (state, action) => {
      delete state.quoteboards[action.payload];
    });

    builder.addMatcher(
      isAnyOf(addQuoteboardSymbols.fulfilled, moveQuoteboardSymbols.fulfilled, removeQuoteboardSymbols.fulfilled),
      (state, action) => {
        state.quoteboards[action.payload.Id].Symbols = action.payload.Symbols;
      }
    );

    builder.addMatcher(
      isAnyOf(
        initQuoteboards.rejected,
        addQuoteboard.rejected,
        removeQuoteboard.rejected,
        addQuoteboardSymbols.rejected,
        moveQuoteboardSymbols.rejected,
        removeQuoteboardSymbols.rejected
      ),
      (_, action) => {
        eventDispatch('tw.ui.toast', {
          type: 'error',
          title: 'Quoteboard Error',
          message: action.payload
        });
      }
    );
  }
});

export default quoteboardSlice.reducer; 