import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fasl } from '@fortawesome/sharp-light-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { fasr } from '@fortawesome/sharp-regular-svg-icons';
import { Provider } from 'react-redux';
import store from "src/redux/store";
import wsClient from './wsclient/WSClient';

// Register FontAwesome Sharp+Light (fasl) Library
library.add(fas, fasl, fass, fasr);

// Pass reference to store into WSClient
wsClient.store = store;

// Initialize LogRocket
// LogRocket.init(config.TW_LOGROCKET_ID);
// setupLogRocketReact(LogRocket);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
